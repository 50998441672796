import { PayloadAction } from "@reduxjs/toolkit";
import { TCarSlice } from "../../../Type/Car/TCarSlice/TCarSlice";
import { TCarProduct } from "../../../Type/Car/Form/TCarProduct";
import { TAddonsValueType } from "../../../Type/Car/Form/TAddonsValueType";
import { TFieldData } from "../../../Type/Common/TFieldData";


const SET_SELECTED_QUOTE_DATA = (
  state: TCarSlice,
  action: PayloadAction<TCarProduct>
) => {
  const data = {
    ...state,
    SELECTED_QUOTE_DATA: action.payload,
  };

  return data;
};

const SAVE_ALL_QUOTES_DATA = (
  state: TCarSlice,
  action: PayloadAction<TCarProduct[]>
) => {
  const data = {
    ...state,
    ALL_QUOTES_DATA: action.payload,
  };

  return data;
};

const BULK_UPDATE = (state: TCarSlice, action: PayloadAction<TCarSlice>) => {
  const data = {
    ...state,
    ...action.payload,
  };

  return data;
};

const UPDATE_SORT_BY = (
  state: TCarSlice,
  action: PayloadAction<"LOW_TO_HIGH" | "HIGH_TO_LOW">
) => {
  const data = {
    ...state,
    SORT_BY: action.payload,
  };

  return data;
};

const UPDATE_PREMIUM_DETAILS_IN_SELECTED_QUOTE = (
  state: TCarSlice,
  action: PayloadAction<any>
) => {
  const {} = action.payload;
  let data: TCarSlice = {
    ...state,
  };

  if (data.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails) {
    data = {
      ...data,
      SELECTED_QUOTE_DATA: {
        ...state.SELECTED_QUOTE_DATA,

        quotationDetail: {
          ...data.SELECTED_QUOTE_DATA.quotationDetail,
          premiumDetails: {
            ...state.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails,
            ...action.payload,
          },
        },
      },
    };
  }
  return data;
};

type KEY_DROPDOWN_MASTER = keyof TAddonsValueType;
const UPDATE_ADDON_USER_VALUE = (
  state: TCarSlice,
  action: PayloadAction<{ key: KEY_DROPDOWN_MASTER; value: TFieldData }[]>
) => {
  let data: TCarSlice = {
    ...state,
  };
  for (const { key, value } of action.payload) {
    data = {
      ...data,
      ADDON_USER_VALUE: {
        ...state.ADDON_USER_VALUE,
        [key]: value,
      },
    };
  }

  return data;
};

const IDVAPPLY_STATUS = (state: TCarSlice, action: PayloadAction<boolean>) => {
  const data = {
    ...state,
    IDVApply_Status: action.payload,
  };
  return data;
};

export const CAR_QUOTE_REDUCERS = {
  SET_SELECTED_QUOTE_DATA,
  SAVE_ALL_QUOTES_DATA,
  UPDATE_SORT_BY,
  UPDATE_PREMIUM_DETAILS_IN_SELECTED_QUOTE,
  UPDATE_ADDON_USER_VALUE,
  BULK_UPDATE,
  IDVAPPLY_STATUS,
};

export type CarQuoteReducers = typeof CAR_QUOTE_REDUCERS;
