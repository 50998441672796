import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";
import JobDetailFillPopup from "./JobDetailFillPopup/JobDetailFillPopup";

function Career() {
  const isMobile = useIsMobile();
  const [openPopup, setOpenPopup] = useState(false);

  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
  });

  const claimForData = [
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    {
      label: "Term Insurance",
      value: "Term Insurance",
    },
    {
      label: "Car Insurance",
      value: "Car Insurance",
    },
    {
      label: "Bike Insurance",
      value: "Bike Insurance",
    },
  ];
  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="responsive-wrapper">
      <JobDetailFillPopup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
      />
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox career">
              <h1 style={{ textAlign: "center" }}>Career</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="form-section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h2 style={{ textAlign: "center", marginBottom: "12px" }}>
              Join Our Team
            </h2>
            <p style={{ marginBottom: "24px", textAlign: "center" }}>
              We are looking for talented and enthusiastic candidates for our
              ever growing talent pool. If you want to grow with us ,join our
              vibrant team.
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
            <img
              src="./images/career-img.svg"
              alt="career"
              className="career-img"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="JD-box">
              <span className="job_tag">Sales Consultant</span>
              <p className="jd_p" style={{ color: COLORS.black }}>
                <span style={{ fontWeight: 600, color: COLORS.black }}>
                  Role:{" "}
                </span>
                Assist customers in buying various financial products, focusing
                on insurance policies, over the phone.
              </p>
              <p className="jd_p" style={{ color: COLORS.black }}>
                <span style={{ fontWeight: 600, color: COLORS.black }}>
                  Eligibility: 
                </span>
                Seeking Graduates or higher education applicants; regardless of
                experience. Fluency in{" "}
                <span style={{ color: "rgba(37, 37, 37, 1)", fontWeight: 600 }}>
                  English
                </span>{" "}
                or
                <span style={{ color: "rgba(37, 37, 37, 1)", fontWeight: 600 }}>
                  Hindi
                </span>
                language is mandatory. Proficiency in regional languages
                <span style={{ color: "rgba(37, 37, 37, 1)", fontWeight: 600 }}>
                  (Telugu, Marathi, Kannada, Tamil, Malayalam Bengali, Gujarati)
                </span>
                is desirable.
              </p>
              <CustomButton
                className="primaryBtn"
                text="Apply Now"
                onClick={() => {
                  setOpenPopup(true);
                }}
                fullWidth={false}
                variant="contained"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Career;
