import { createSlice } from "@reduxjs/toolkit";
import { GET_CURRENT_DATE } from "../../../SupportingFiles/HelpingFunction";
import { CAR_QUOTE_REDUCERS } from "./CarQuoteReducer";
import { CAR_REDUCERS } from "./CarReducer";
import { TCarSlice } from "../../../Type/Car/TCarSlice/TCarSlice";

const intitialState: TCarSlice = {
  SORT_BY: "LOW_TO_HIGH",
  ALL_QUOTES_DATA: [],
  SELECTED_QUOTE_DATA: {
    buy_online_code: "",
    company_code: "",
    id: 0,
    loader: false,
    logo: "",
    product_code: "",
    product_type: "Comprehensive",
    quotationDetail: {
      premiumDetails: {
        passerngerCover: 0,
        includedPersonalBelonging: 0,
        personalBelonging: 0,
        error: false,
        freeRoadSideAssistance: 0,
        tyreSecureCover: 0,
        fg_plan_type: "",
        tariffDiscount: 0,
        exshowroomPrice: 0,
        minimumIDV: 0,
        maximumIDV: 0,
        IDV: 0,
        basicOD: 0,
        basicTP: 0,
        netPremium: 0,
        ncbDiscount: 0,
        odDiscount: 0,
        antiTheftDiscount: 0,
        associationDiscount: 0,
        ePolicyDiscount: 0,
        otherDiscount: 0,
        deductibleDiscount: 0,
        ambulanceChargesCover: 0,
        consumablesCover: 0,
        depreciationCover: 0,
        hospitalCashCover: 0,
        hydrostaticLockCover: 0,
        keyReplacementCover: 0,
        paOwnerDriver: 0,
        engineGearBoxProtection: 0,
        noClaimBonusSameSlab: 0,
        roadSideAssistance: 0,
        medicalExpenses: 0,
        accessoryCoverCharge: 0,
        llDriver: 0,
        paFamily: 0,
        invoicePriceCover: 0,
        driverCover: 0,
        seatingCapacity: 0,
        includedPAOwnerDriver: 0,
        cngKitOD: 0,
        cngKitTP: 0,
      },
      CompanyDetails: {
        company_code: "",
        name: "",
        short_desc: "",
        logo: "",
        claim_ratio: "",
      },
      productDetails: {
        id: 0,
        product_name: "",
        brochure: "",
        product_code: "",
        rate_calc_method: "",
        proposal_method: "",
        tax_calc_method: "",
        discount_calc_method: "",
        rating: 3,
        product_desc: "",
        buy_online_code: "",
        policy_covers: "",
        company_product_map: {
          company_code: "",
        },
      },
      cashlessGarageLists: [],
    },
  },
  DROPDOWN_DATA: {
    SORT_BY: [
      { label: "LOW_TO_HIGH", value: "Low to High" },
      { label: "HIGH_TO_LOW", value: "High to low" },
    ],

    MAKE_MODEL_LIST: [],
    PASSENGER_COVER_DATA: [
      { label: "50000", value: "50,000" },
      { label: "60000", value: "60,000" },
      { label: "70000", value: "70,000" },
      { label: "80000", value: "80,000" },
      { label: "90000", value: "90,000" },
      { label: "100000", value: "1,00,000" },
      { label: "110000", value: "1,10,000" },
      { label: "120000", value: "1,20,000" },
      { label: "130000", value: "1,30,000" },
      { label: "140000", value: "1,40,000" },
      { label: "150000", value: "1,50,000" },
      { label: "160000", value: "1,60,000" },
      { label: "170000", value: "1,70,000" },
      { label: "180000", value: "1,80,000" },
      { label: "190000", value: "1,90,000" },
      { label: "200000", value: "2,00,000" },
    ],
    POPULAR_MAKE: [
      "Hyundai",
      "Maruti",
      "Volkswagen",
      "Tata",
      "Mahindra",
      "Honda",
      "Kia",
      "Skoda",
      "Toyota",
      "Audi",
      "BMW",
      "Mercedes",
    ],
    RTO_LIST: [],
    NCB: ["0", "20", "25", "35", "45", "50"],
    FUEL_TYPE_LIST: [],
    MAKE_LIST: [],
    MODEL_LIST: [],
    PREVIOUS_INSURER_LIST: [],
    VARIANT_LIST: [],
    PLAN_TYPE: [
      { label: "Comprehensive", value: "Comprehensive" },
      { label: "Third Party", value: "Third Party" },
    ],
    GENDER_DATA: [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
    ],
    RELATIONSHIP_DATA: [
      { label: "Father", value: "Father" },
      { label: "Mother", value: "Mother" },
      { label: "Brother", value: "Brother" },
      { label: "Sister", value: "Sister" },
      { label: "Grand Father", value: "Grand Father" },
      { label: "Grand Mother", value: "Grand Mother" },
      { label: "Brother In Law", value: "Brother In Law" },
      { label: "Mother In Law", value: "Mother In Law" },
      { label: "Daughter", value: "Daughter" },
      { label: "Son", value: "Son" },
      { label: "Wife", value: "Wife" },
    ],
    PA_OWNER_COVER_DATA: [
      {
        label: "Owner does not have valid driving license",
        value: "Owner does not have valid driving license",
      },
      {
        label: "Already having PA/SCPA cover",
        value: "Already having PA/SCPA cover",
      },
    ],

    OWNER_MARTIAL_STATUS_DATA: [
      { label: "Married", value: "Married" },
      { label: "Single", value: "Single" },
      { label: "Divorced", value: "Divorced" },
      { label: "Widow", value: "Widow" },
      { label: "Widower", value: "Widower" },
    ],
  },
  // ADD_FORM: {
  //   personalBelonging: 0,
  //   new_ncb: { value: "", warning: false },
  //   fullname: { value: "", warning: false },
  //   business_type: "Rollover",
  //   invoice_date: {
  //     value: GET_CURRENT_DATE(),
  //     warning: false,
  //   },
  //   claimed: "No",
  //   make_model: { value: "", warning: false },
  //   fuel_type: { value: "", warning: false },
  //   make: { value: "", warning: false },
  //   mobile: { value: "", warning: false },
  //   model: { value: "", warning: false },
  //   prev_ncb: "0",
  //   previous_insurer: { value: "", warning: false },
  //   reg_date: { value: null, warning: false },
  //   reg_no: { value: "", warning: false },
  //   variant_cc: { value: "", warning: false },
  //   rto: { value: "", warning: false },
  //   policy_expiry_date: { value: null, warning: false },
  //   automobile_association: "0",
  //   cng_kit: "",
  //   consumables_cover: 0,
  //   depreciation_cover: 0,
  //   driver_cover: 0,
  //   electric_accessory: "",
  //   engine_gear_box_protection: 0,
  //   invoice_cover: 0,
  //   key_replacement_cover: 0,
  //   no_claim_bonus_same_slab: 0,
  //   non_electric_accessory: "",
  //   pa_owner_driver: 0,
  //   passernger_cover: 0,
  //   road_side_assistance: 0,
  //   tyre_secure_cover: 0,
  // },
  ADD_FORM: {
    owner_name: { value: "", warning: false },
    business_type: "Rollover",
    claimed: "No",
    make_model: { value: "", warning: false },
    fuel_type: { value: "", warning: false },
    make: { value: "", warning: false },
    mobile: { value: "", warning: false },
    model: { value: "", warning: false },
    prev_ncb: "0",
    previous_insurer: { value: "", warning: false },
    reg_date: { value: null, warning: false },
    reg_no: { value: "", warning: false },
    variant_cc: { value: "", warning: false },
    rto: { value: "", warning: false },
    policy_expiry_date: { value: null, warning: false },
    anti_theft_device: "No",
    automobile_association: "0",
    deductible: "0",
    vehicle_owned: "Individual",
  },
  DISCOUNT_FORM: {
    anti_theft_device: "No",
    deductible: 0,
    vehicle_owned: "Individual",
  },
  ADD_FORM_RESPONSE: {
    business_type: "",
    car_master_data_id: 0,
    claim_made: "No",
    cubic_capacity: "",
    fuel_type: "",
    insurance_type: "",
    make: "",
    make_model: "",
    manufacture_date: "",
    model: "",
    new_ncb: "0",
    new_policy_date: "",
    policy_expiry_date: "",
    previous_insurer: "",
    previous_ncb: "0",
    quote_no: "",
    registration_date: "",
    registration_no: "",
    rto_code: "",
    seating_capacity: "",
    tp_policy_start_date: "",
    tp_previous_insurer: "",
    variant: "",
    variant_cc: "",
    idv: "",
    actual_reg_no: "",
  },
  PLAN_TYPE: "",
  PAGE_STATUS: false,
  QUOTE_LOADER: true,
  ADDON_STATUS: {
    depreciation_cover: { label: "Zero Depreciation", value: false },
    pa_owner_cover: { label: "PA Owner Cover", value: false },
    personalBelonging: {
      label: "Personal Belonging",
      value: false,
    },
    rsa: { label: "24x7 RSA", value: false },
    driver_cover: { label: "Driver Cover", value: false },
    consumable_cover: { label: "Consumable Cover", value: false },
    engine_cover: { label: "Engine Cover", value: false },
    invoice_cover: { label: "Invoice Cover", value: false },
    key_replacement: { label: "Key Replacement", value: false },
    ncb_cover: { label: "NCB Cover", value: false },
    tyre_cover: { label: "Tyre Cover", value: false },
    passenger_cover: { label: "Passenger Cover (₹)", value: false },
    electrical: { label: "Electrical (₹)", value: false },
    non_electrical: { label: "Non-Electrical (₹)", value: false },
    cng_kit: { label: "CNG Kit (₹)", value: false },
  },
  IDVApply_Status: false,
  ADDON_USER_VALUE: {
    idv: { value: "", warning: false },
    cngKitValue: { value: "", warning: false },
    electricalAcessories: { value: "", warning: false },
    nonElectricalAcessories: { value: "", warning: false },
    passengerCover: { value: "", warning: false },
  },
  OWNER_DETAILS: {
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    dob: { value: "", warning: false },
    gender: { value: "", warning: false },
    maritalStatus: { value: "", warning: false },
    pacover: { value: "", warning: false },
    validPuc: true,
    vehicleRegistered: false,
    isPolicyComprehensive: true,
    includePAOwner: false,
    gst: { value: "", warning: false },
  },
  CKYC_DETAILS: {
    propFullName: "",
    ProposerDob: "",
    docID: "",
    cusdtomertype: "",
    verificationDoc: "",
    gender: "",
  },
  NOMINEE_DETAILS: {
    fullName: { value: "", warning: false },
    dob: { value: "", warning: false },
    relationship: { value: "", warning: false },
  },
  ADDRESS_DETAILS: {
    pincode: { value: "", warning: false },
    city: "",
    state: "",
    addressLineOne: { value: "", warning: false },
    addressLineTwo: { value: "", warning: false },
    isMailingAddressSame: true,
    mailingPinCode: { value: "", warning: false },
    mailingCity: "",
    mailingState: "",
    mailingAddressLineOne: { value: "", warning: false },
    mailingAddressLineTwo: { value: "", warning: false },
  },
  VEHICLE_DETAILS: {
    id: "",
    make_id: "",
    model_id: "",
    variant_id: "",
    api_unique_id: "",
    old_api_id: "",
    registration_no: "",
    owner_name: "",
    owner_gender: "",
    owner_email: "",
    owner_dob: "",
    registration_date: "",
    chasis_no: "",
    engine_no: "",
    vehicle_class: "",
    make: "",
    model: "",
    variant: "",
    cubic_capacity: "",
    seating_capacity: "",
    fuel_type: "",
    maker: "",
    rto: "",
    score: "",
    address_p_house: "",
    address_p_pincode: "",
    address_p_area: "",
    address_p_district: "",
    address_p_city: "",
    address_p_state: "",
    new: "",
    created: "",
    updated: "",
    make_model: "",
    variant_cc: "",
    registration_year: "",
  },
};

export const CarSlice = createSlice({
  name: "CAR",
  initialState: intitialState,
  reducers: { ...CAR_REDUCERS, ...CAR_QUOTE_REDUCERS },
});
