import React from "react";

interface LoaderProps {
  size: "small" | "large"; // Define size prop
}

const Loader: React.FC<LoaderProps> = ({ size }) => {
  // Define size-related properties
  const strokeWidth = size === "small" ? 4 : 7;
  const r = size === "small" ? 10 : 20.5;
  const strokeDasharray = size === "small" ? 100 : 160;
  const animationDuration = size === "small" ? "3s" : "2.5s"; // Set animation duration

  const progressAnimation: React.CSSProperties = {
    animation: `progressAnimation ${animationDuration} linear infinite`, // Use animation duration based on size
  };

  return <div className="loader"></div>;
};

export default Loader;
