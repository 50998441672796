import { PayloadAction } from "@reduxjs/toolkit";
import { TDropdown } from "../../Type/Common/TDropdown";
import { TCommonDropdownSlice } from "../../Type/TTCommonSlice/TCommonDropdownSlice";


type ValueTypes = keyof TCommonDropdownSlice;
const UPDATE_DROPDOWN_DATA = (
  state: TCommonDropdownSlice,
  action: PayloadAction<{ key: ValueTypes; value: TDropdown[] }>
) => {
  const { key, value } = action.payload;
  const data: TCommonDropdownSlice = {
    ...state,
    [key]: value,
  };

  return data;
};

export const COMMON_DROPDOWN_REDUCERS = {
  UPDATE_DROPDOWN_DATA,
};
