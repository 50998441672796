import { createSlice } from "@reduxjs/toolkit";
import { COMMON_DROPDOWN_REDUCERS } from "./CommonDropdownReducers";
import { TCommonDropdownSlice } from "../../Type/TTCommonSlice/TCommonDropdownSlice";

const initialState: TCommonDropdownSlice = {
  CUSTOMER_TYPE: [
    { label: "Individual", value: "Individual" },
    { label: "Organisation", value: "Organisation" },
  ],
  POA: [],
  POI: [],
  POV: [],
  GENDER: [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ],
  AGE: [],
  HEIGHT_FEET: [
    { label: "1 Feet", value: "1 Feet" },
    { label: "2 Feet", value: "2 Feet" },
    { label: "3 Feet", value: "3 Feet" },
    { label: "4 Feet", value: "4 Feet" },
    { label: "5 Feet", value: "5 Feet" },
    { label: "6 Feet", value: "6 Feet" },
    { label: "7 Feet", value: "7 Feet" },
  ],
  HEIGHT_INCH: [
    { label: "0 Inch", value: "0 Inch" },
    { label: "1 Inch", value: "1 Inch" },
    { label: "2 Inch", value: "2 Inch" },
    { label: "3 Inch", value: "3 Inch" },
    { label: "4 Inch", value: "4 Inch" },
    { label: "5 Inch", value: "5 Inch" },
    { label: "6 Inch", value: "6 Inch" },
    { label: "7 Inch", value: "7 Inch" },
    { label: "8 Inch", value: "8 Inch" },
    { label: "9 Inch", value: "9 Inch" },
    { label: "10 Inch", value: "10 Inch" },
    { label: "11 Inch", value: "11 Inch" },
  ],
};

const ageData = [];
for (let i = 18; i <= 100; i++) {
  ageData.push({
    label: `${i}`,
    value: `${i} Years`,
  });
}

initialState.AGE = ageData;

export const CommonDropdownSlice = createSlice({
  name: "CommonDropdownSlice",
  initialState,
  reducers: COMMON_DROPDOWN_REDUCERS,
});

export const {} = CommonDropdownSlice.actions;

export default CommonDropdownSlice.reducer;
