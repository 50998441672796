import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { store } from "./Store/Store/Store";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://fbf26e7bf001e5a8f3ca13416bae111a@o4506864219521024.ingest.us.sentry.io/4507537089101824",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ToastContainer theme="colored" />
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  reportWebVitals();
}

renderApp();
