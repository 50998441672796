import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { URL_CONSTANTS } from "../../Routing/Path/CarurlConstant";
import { AddTermDTO } from "./TermDTO";

const addTermFormData = (
  onSuccess: Function,
  onError: Function,
  dto: AddTermDTO
) => {
  PostAPI.call(
    URL_CONSTANTS.ADD_TERM_FORM,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const termQuotes = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    URL_CONSTANTS.TERM_QUOTES,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const termProductListing = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.TERM_QUOTES_PRODUCT,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const updateDetails = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    URL_CONSTANTS.TERM_UPDATE_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const BUY_ACTION = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    URL_CONSTANTS.BUY_ACTION,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const RIDERS_BENEFITS_DETAIL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.TERM_RIDERS_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_PROPOSER_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.PERPOSAL_TERM_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const PROPOSER_OCCUPATION_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.PROPOSER_OCCUPATION_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const PROPOSER_QUALIFICATION_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    URL_CONSTANTS.PROPOSER_QUALIFICATION_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_REDIRECTION_URL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    quote_no: string;
    criticalIllnessSumAssured: string;
    accDeathSumAssured: string;
  }
) => {
  PostAPI.call(
    URL_CONSTANTS.GET_REDIRECTION_URL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const TERM_SERVICES = {
  addTermFormData,
  termQuotes,
  termProductListing,
  updateDetails,
  BUY_ACTION,
  RIDERS_BENEFITS_DETAIL,
  UPDATE_PROPOSER_DETAILS,
  PROPOSER_OCCUPATION_DETAILS,
  PROPOSER_QUALIFICATION_DETAILS,
  GET_REDIRECTION_URL,
};
