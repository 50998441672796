import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import CustomButton from "../../../../Component/InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../Component/InputFields/RKTextField/RKTextField";
import Popup from "../../../../Component/Popup/Popup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const JobDetailFillPopup: React.FC<Props> = ({ open, handleClose }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    claimfor: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    claimfor: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });
  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  console.log("rr", selectedFile);
  return (
    <Popup
      open={open}
      handleClose={handleClose}
      variant="small"
      title=""
      content={
        <Box padding="24px">
          <Grid
            container
            spacing={2}
            textAlign="center"
            justifyContent={"center"}
          >
            <Grid item xs={12}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Name"}
                value={formFields.name.value}
                attrName={"name"}
                value_update={updateFieldsState}
                warn_status={formFields.name.warn_status}
              />
            </Grid>
            <Grid item xs={12}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Mobile"}
                value={formFields.mobile.value}
                attrName={"mobile"}
                value_update={updateFieldsState}
                warn_status={formFields.mobile.warn_status}
              />
            </Grid>
            <Grid item xs={12}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Email"}
                value={formFields.email.value}
                attrName={"email"}
                value_update={updateFieldsState}
                warn_status={formFields.email.warn_status}
              />
            </Grid>
            <Grid item xs={12} className="fileupload-field mb-2">
              {!selectedFile && (
                <label htmlFor="file-upload">
                  <CloudUploadIcon />
                  Upload Curriculum Vitae in PDF / DOC Format (size less than 10
                  MB)
                </label>
              )}
              {selectedFile && <p>{selectedFile.name}</p>}
              <input
                id="file-upload"
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={() => {}}
                style={{ display: "none" }}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomButton
                className="primaryBtn"
                text="Submit Details"
                onClick={() => {}}
                fullWidth={false}
                variant="contained"
              />
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

export default JobDetailFillPopup;
