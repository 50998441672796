import { createSlice } from "@reduxjs/toolkit";
import { THealthSlice } from "../../../Type/Health/THealthSlice/THealthSlice";
import { HEALTH_REDUCERS } from "./HealthReducer";

const initialState: THealthSlice = {
  QUOTE_LOADER: false,
  NOMINEE_RELATIONSHIP_STATUS: "OTHER",
  DROPDOWN_DATA: {
    TERM: [
      { label: "1", value: "1 Year" },
      { label: "2", value: "2 Years" },
      { label: "3", value: "3 Years" },
    ],
    SORT_BY: [
      { label: "Low-High", value: "Low-High" },
      { label: "High-Low", value: "High-Low" },
    ],
    AGE: [],
    CHILD_DATA: [
      { value: "son", label: "son" },
      { value: "Daughter", label: "Daughter" },
    ],
    SUM_INSURED: [
      { value: "1 Lakh", label: "100000" },
      { value: "2 Lakh", label: "200000" },
      { value: "4 Lakh", label: "400000" },
      { value: "5 Lakh", label: "500000" },
      { value: "6 Lakh", label: "600000" },
      { value: "7 Lakh", label: "700000" },
      { value: "8 Lakh", label: "800000" },
      { value: "9 Lakh", label: "900000" },
      { value: "10 Lakh", label: "1000000" },
      { value: "12 Lakh", label: "1200000" },
      { value: "15 Lakh", label: "1500000" },
      { value: "16 Lakh", label: "1600000" },
      { value: "17 Lakh", label: "1700000" },
      { value: "18 Lakh", label: "1800000" },
      { value: "19 Lakh", label: "1900000" },
      { value: "20 Lakh", label: "2000000" },
      { value: "25 Lakh", label: "2500000" },
      { value: "30 Lakh", label: "3000000" },
      { value: "40 Lakh", label: "4000000" },
      { value: "50 Lakh", label: "5000000" },
      { value: "60 Lakh", label: "6000000" },
      { value: "75 Lakh", label: "7500000" },
      { value: "1 Crore", label: "10000000" },
      { value: "1.5 Crore", label: "15000000" },
    ],
    OCCUPATION: [],
    MARTIAL: [],
    RELATIONSHIP: [],
    GLOBALCOVERAGE: [
      { value: "Not Applicable", label: "Not Applicable" },
      { value: "WW Coverage", label: "WW Coverage" },
      { value: "WW Excl. US and Canada", label: "WW Excl. US and Canada" },
    ],
  },

  ADDON_DETAILS: {
    NCBSuper: false,
    SmartSelect: false,
    AirAmbulanceCover: false,
    CareShield: false,
    GlobalCoverage: { value: "", warning: false },
  },
  ADD_FORM: {
    spouse_relationship: { value: "", warning: false },
    age: { value: "", warning: false },
    children: "",
    gender: { value: "", warning: false },
    pincode: { value: "", warning: false },
    whom_to_insure: "One Adult",
    mobile: { value: "", warning: false },
    name: { value: "", warning: false },
    son_count: { value: "0", warning: false },
    daughter_count: { value: "0", warning: false },
    self_dob: { value: "", warning: false },
    spouse_dob: { value: "", warning: false },
    son_status: false,
    daughter_status: false,
    self_status: true,
    spouse_status: false,
    child_one_dob: { value: "", warning: false },
    child_two_dob: { value: "", warning: false },
    child_three_dob: { value: "", warning: false },
    child_four_dob: { value: "", warning: false },
  },
  ADD_FORM_RESPONSE: {
    sum_insured: 0,
    quote_no: "",
    module: "",
    insurance_type: "",
    term: 0,
    cover: "",
    city: "",
    state: "",
    child: 0,
    adult: 0,
    mobile: 0,
    pincode: "",
    sort_by: "Low-High",
  },
  KYC_DETAILS: {
    propFullName: { value: "", warning: false },
    ProposerDob: { value: "", warning: false },
    verificationDoc: { value: "", warning: false },
    docID: { value: "", warning: false },
    cusdtomertype: { value: "", warning: false },
    gender: { value: "", warning: false },
    addressDoc: { value: "", warning: false },
    verificationDocFile: { value: "", warning: false },
    addressDocFile: { value: "", warning: false },
  },
  ALL_QUOTES_DATA: [
    {
      CashlessHospitalLists: [],
      sumInsured: "",
      productDetails: {
        brochure: "",
        buy_online_code: "",
        product_type_code: "",
        company_product_map: { company_code: "" },
        discount_calc_action: "",
        health_form_validation: "",
        id: 0,
        is_online: "",
        pre_existing_cover: "",
        prem_calc_action: "",
        product_code: "",
        product_desc: "",
        product_name: "",
        proposal_method: "",
        rate_calc_method: "",
        rating: "",
        room_cover: "",
      },
      premiumDetails: {
        basePremium: 0,
        finalPremium: 0,
        quoteId: "",
        serviceTax: 0,
      },
      SpecialFeatureLists: [
        { code: "", description: "", show_desc: 0, title: "" },
      ],
      CompanyDetails: {
        claim_ratio: "",
        company_code: "",
        logo: "https://devstatic.masuraksha.com/images/Go-Digit-General-Insurance.svg",
        name: "",
        short_desc: "",
      },
      loading: true,
    },
    {
      CashlessHospitalLists: [],
      sumInsured: "",
      productDetails: {
        product_type_code: "",
        brochure: "",
        buy_online_code: "",
        company_product_map: { company_code: "" },
        discount_calc_action: "",
        health_form_validation: "",
        id: 0,
        is_online: "",
        pre_existing_cover: "",
        prem_calc_action: "",
        product_code: "",
        product_desc: "",
        product_name: "",
        proposal_method: "",
        rate_calc_method: "",
        rating: "",
        room_cover: "",
      },
      premiumDetails: {
        basePremium: 0,
        finalPremium: 0,
        quoteId: "",
        serviceTax: 0,
      },
      SpecialFeatureLists: [
        { code: "", description: "", show_desc: 0, title: "" },
      ],
      CompanyDetails: {
        claim_ratio: "",
        company_code: "",
        logo: "https://devstatic.masuraksha.com/images/Star-Health-and-Allied-Insurance-Company-Limited.svg",
        name: "",
        short_desc: "",
      },
      loading: true,
    },
    {
      CashlessHospitalLists: [],
      sumInsured: "",
      productDetails: {
        product_type_code: "",
        brochure: "",
        buy_online_code: "",
        company_product_map: { company_code: "" },
        discount_calc_action: "",
        health_form_validation: "",
        id: 0,
        is_online: "",
        pre_existing_cover: "",
        prem_calc_action: "",
        product_code: "",
        product_desc: "",
        product_name: "",
        proposal_method: "",
        rate_calc_method: "",
        rating: "",
        room_cover: "",
      },
      premiumDetails: {
        basePremium: 0,
        finalPremium: 0,
        quoteId: "",
        serviceTax: 0,
      },
      SpecialFeatureLists: [
        { code: "", description: "", show_desc: 0, title: "" },
      ],
      CompanyDetails: {
        claim_ratio: "",
        company_code: "",
        logo: "https://devstatic.masuraksha.com/images/IFFCO-Tokio-General-Insurance-Co-Ltd.svg",
        name: "",
        short_desc: "",
      },
      loading: true,
    },
  ],
  POLICY_TENURE: 1,
  SELECTED_QUOTE_DATA: {
    CashlessHospitalLists: [],
    sumInsured: "",
    productDetails: {
      brochure: "",
      buy_online_code: "",

      company_product_map: { company_code: "" },
      discount_calc_action: "",
      health_form_validation: "",
      id: 0,
      is_online: "",
      pre_existing_cover: "",
      prem_calc_action: "",
      product_code: "",
      product_type_code: "",
      product_desc: "",
      product_name: "",
      proposal_method: "",
      rate_calc_method: "",
      rating: "",
      room_cover: "",
    },
    premiumDetails: {
      basePremium: 0,
      finalPremium: 0,
      quoteId: "",
      serviceTax: 0,
    },
    SpecialFeatureLists: [
      { code: "", description: "", show_desc: 0, title: "" },
    ],
    CompanyDetails: {
      claim_ratio: "",
      company_code: "",
      logo: "",
      name: "",
      short_desc: "",
    },
    loading: true,
  },
  BUYACTION_RESPONSE_DATA: {
    quote_no: "",
    product_code: "",
    product_id: "",
    company_code: "",
    sum_insured: "",
    premium: "",
    insurance_type: "",
    quoteId: "",
    FinalPremiumm: "",
    ProductDetails: {
      id: 0,
      product_name: "",
      brochure: "",
      product_code: "",
      rate_calc_method: "",
      proposal_method: "",
      tax_calc_method: "",
      discount_calc_method: "",
      rating: 2,
      product_desc: "",
      buy_online_code: "",
      prem_calc_action: "",
      tax_calc_action: "",
      discount_calc_action: "0",
      health_form_validation: {
        self_min_age: 0,
        self_max_age: 0,
        spouse_min_age: 0,
        spouse_max_age: 0,
        child_min_age: 0,
        child_max_age: 0,
      },
    },
    CompanyDetails: {
      company_code: "",
      name: "",
      short_desc: "",
      logo: "",
      claim_ratio: "",
    },
    amountWithGst: "",
    term: "",
    ppterm: "",
    income: "",
    incomeOne: "",
    policyTenure: "",
  },

  PAGE_STATUS: false,
  Preview_Loader: false,
};

const ageData = [];
for (let i = 18; i <= 110; i++) {
  ageData.push({
    value: `${i} Years`,
    label: `${i} Years`,
  });
}

initialState.DROPDOWN_DATA.AGE = ageData;

const SUM_INSURED_DATA = [];
for (let i = 1; i <= 150; i += i < 20 ? 1 : i < 50 ? 5 : 10) {
  SUM_INSURED_DATA.push({
    value: `${i >= 100 ? `${i / 100} Cr` : `${i} Lakh`}`,
    label: `${i}00000`,
  });
}

initialState.DROPDOWN_DATA.SUM_INSURED = SUM_INSURED_DATA;

export const HealthSlice = createSlice({
  name: "Health",
  initialState,
  reducers: HEALTH_REDUCERS,
});

export default HealthSlice.reducer;
