import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { CarSlice } from "../Reducer/Car/CarSlice";
import { CommonDropdownSlice } from "../CommonDropdown/CommonDropdownSlice";
import { TWSlice } from "../Reducer/TW/TwSlice";
import { HealthSlice } from "../Reducer/Health/HealthSlice";
import { TermSlice } from "../Reducer/Term/TermSlice";
import { UserProfileSlice } from "../Reducer/Account/UserProfileSlice";

const rootReducer = combineReducers({
  CommonDropdownData: CommonDropdownSlice.reducer,

  Car: CarSlice.reducer,
  Tw: TWSlice.reducer,
  Health: HealthSlice.reducer,
  Term: TermSlice.reducer,
  USER_STATE: UserProfileSlice.reducer,
});

// Configure Redux Persist with session storage
const persistConfig = {
  key: "root",
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the Redux Persist store
const persistor = persistStore(store);

export { persistor, store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
