import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import "./Footer.scss";

function Footer() {
  const navigate = useNavigate();
  return (
    <Box className="footer">
      <Grid container spacing={3}>
        <Grid xs={3}>
          <img
            src="./images/1clickpolicy-white-logo.svg"
            height="60px"
            alt="1clickpolicy"
          />
          <p className="my-6">
            A-26/1, DLF Phase 1, Golf course Road ,Gurgaon,
            Haryana.
          </p>
          <p>IRDAI License No: 713</p>
          <p>Valid upto: 07-06-2026</p>
          <p className="mt-6">
            <span>CIN:</span> U67345MH2C123456
          </p>
        </Grid>
        <Grid xs={3}>
          <h5>Quick Links</h5>
          <ul>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.HOME)}>Home</Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                About Us
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CONTACT)}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CLAIM)}>Claim</Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.COMPLAINT)}>
                Complaint
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}>
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRIVACY_POLICY)}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.DISCLAIMER)}>
                Disclaimer
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.REFUND_CANCELATION)}>
                Refund & Policy Cancellation
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={3}>
          <h5>Services</h5>
          <ul>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}>
                Health Insurance
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                Car Insurance
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                Bike Insurance
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                Term Insurance
              </Link>
            </li>
            {/* <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                Investment Plan
              </Link>
            </li> */}
          </ul>
        </Grid>
        <Grid xs={3}>
          <h5>Get in Touch</h5>
          <p className="mt-4">
            <span>Contact:</span>
            <br /> +91 9876543210
          </p>
          <p className="mt-6">
            <span>Support Mail:</span>
            <br />{" "}
            <Link href="mailto:support@1clickpolicy.com">
              support@1clickpolicy.com
            </Link>
          </p>
        </Grid>
        <Grid xs={12}>
          <p className="disclaimer">
            DISCLAIMER: All the images used on 1clickpolicy.com have either been
            purchased or are used with permission of the copyright holder.
            Images from 1clickpolicy.com may not be copied or used elsewhere
            without obtaining appropriate permissions from the copyright
            holders.
          </p>
        </Grid>
        <Grid xs={12} textAlign="center">
          <p>
            © {new Date().getFullYear()} 1 ClickPolicy | All Rights Reserved.
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}
export default Footer;
